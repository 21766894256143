/* this implementation is original ported from https://github.com/logaretm/vue-use-web by Abdelrahman Awad */
import { shallowRef, ref, Ref } from '@nuxtjs/composition-api';
import { isClient } from '~/helpers/check-environment';

export interface UseGeolocationOptions {
  immediate?: boolean,
  enableHighAccuracy?: boolean,
  maximumAge?: number,
  timeout?: number,
  navigator?: any,
}

/**
 * Reactive Geolocation API.
 */
export function useGeolocation(options: UseGeolocationOptions = {}) {
  const {
    enableHighAccuracy = true,
    maximumAge = 30_000,
    timeout = 27_000,
    navigator = isClient ? window.navigator : undefined,
    immediate = true,
  } = options;

  const isSupported = ref(!!(navigator && 'geolocation' in navigator));

  const locatedAt: Ref<number | null> = ref(null);
  const error = shallowRef<GeolocationPositionError | null>(null);
  const coords: Ref<GeolocationPosition['coords']> = ref({
    accuracy: 0,
    latitude: null,
    longitude: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });

  function updatePosition(position: GeolocationPosition) {
    locatedAt.value = position.timestamp;
    coords.value = position.coords;
    error.value = null;
  }

  let watcher: number;

  function resume() {
    if (isSupported.value) {
      watcher = navigator!.geolocation.watchPosition(
        updatePosition,
        (err) => { error.value = err; },
        {
          enableHighAccuracy,
          maximumAge,
          timeout,
        },
      );
    }
  }

  function get() {
    if (isSupported.value) {
      navigator!.geolocation.getCurrentPosition(
        updatePosition,
        (err) => { error.value = err; },
      );
    }
  }

  if (immediate) {
    resume();
  }

  function pause() {
    if (watcher && navigator) navigator.geolocation.clearWatch(watcher);
  }

  return {
    isSupported,
    coords,
    locatedAt,
    error,
    get,
    resume,
    pause,
  };
}

export type UseGeolocationReturn = ReturnType<typeof useGeolocation>;

export default useGeolocation;
