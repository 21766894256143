import type { Price, MediaGalleryItem } from '~/modules/catalog/types';
import type { Product, ProductAttribute } from '~/modules/catalog/product/types';
import { Breadcrumb } from '~/modules/catalog/types';
import { groupedRangeFilters } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';

import {
  BundleProduct, CategoryInterface, GroupedProduct, ProductInterface, CategoryTree, ProductAttachments, PriceTiersWithCustomerGroup, StockItem,
  CustomAttributesInterface, Maybe,
} from '~/modules/GraphQL/types';

import { htmlDecode } from '~/helpers/htmlDecoder';

import {
  availableList,
  isProductInStock,
  // isProductOnlyXLeftInStock,
  isProductOrderNumber, isProductProductWithdrawn,
  ProductAvailable,
} from '~/modules/catalog/product/helpers/stockHelpers';
import { Aggregation } from '@vue-storefront/magento-types';
import {
  getPriceForLoggedCustomer,
  getPriceThreshold,
  getPriceTiersWithCustomerGroup, isLoggedCustomerGroupPrice, isTableForCustomerGroupPrice,
} from '~/modules/catalog/product/helpers/customerGroupPriceHelpers';
import { productAdditionalFields } from '~/modules/GraphQL/customQueries/fragments/fragmentProductAdditionalFields';

export interface ProductGetters {
  getName: (product: ProductInterface) => string;
  isSpecialPrice: (specialPrice, omnibusPrice) => boolean;

  getSlug(product: ProductInterface, category?: CategoryInterface): string;

  getPrice: (product: ProductInterface) => Price;
  getGallery: (product: ProductInterface, maxGallerySize: number) => MediaGalleryItem[];
  getCoverImage: (product: ProductInterface) => string;
  getAttributes: (products: ProductInterface[] | ProductInterface, filters?: Array<string>) => Record<string, ProductAttribute | string>;
  getDescription: (product: ProductInterface) => string;
  getCategoryIds: (product: ProductInterface) => string[];
  getId: (product: ProductInterface) => string;
  getBreadcrumbs?: (product: ProductInterface, category?: CategoryInterface) => Breadcrumb[];
  getCategory(product: Product, currentUrlPath: string): CategoryTree | null;
  getProductRelatedProduct(product: ProductInterface): Product[];
  getProductSku(product: ProductInterface): string;
  getProductThumbnailImage(product: ProductInterface): string;
  getProductUpsellProduct(product: ProductInterface): ProductInterface[];
  getShortDescription(product: ProductInterface): string;
  getTypeId(product: ProductInterface): string;
  getSwatchData(swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined;
  getGroupedProducts(product: GroupedProduct): GroupedProduct['items'] | undefined;
  getBundleProducts(product: BundleProduct): BundleProduct['items'] | undefined;
  getManufacturer(product: ProductInterface): number;
  getVariants(product: ProductInterface): any[];
  getConcatenatedVariantsLabels(product: ProductInterface): string;
  isProductBestseller(product: ProductInterface): boolean;
  isProductEko(product: ProductInterface): boolean;
  getDeliveryIn(delivery_time: number | string | null): string;
  isProductConfigurable(product: ProductInterface): boolean;
  isProductRecommended(product: ProductInterface): boolean;
  isProductVatOnly(product: ProductInterface): boolean;
  getAvailableType(product: ProductInterface): ProductAvailable;
  getDeliveryDate(product: ProductInterface): string;
  getIsNewProduct(product: ProductInterface): boolean;
  getCheapestVariant(product: Product): Product | null;
  getProductAttachments(product: Product): ProductAttachments[] | [] | null;
  getProductEan(product: Product): string | null | number;
  getAgrofag(product: Product): string[];
  getGrupaUpraw(product: Product): string[];
  getActiveSubstance(product: Product): string[];
  getPurpose(product: Product): string[];
  getDosage: (product: Product) => any;
  getUpsellProducts(product: Product): string[];
  getRelatedProducts(product: Product): string[];
  getCrossSellProducts(product: Product): string[];
  getSubstituteProducts(product: Product): string[];
  getPriceTiersWithCustomerGroup(product: ProductInterface): PriceTiersWithCustomerGroup[];
  getPriceForLoggedCustomer(product: ProductInterface): PriceTiersWithCustomerGroup | null;
  getPriceThreshold(product: ProductInterface): PriceTiersWithCustomerGroup[];
  isTableForCustomerGroupPrice(product: ProductInterface): boolean;
  isLoggedCustomerGroupPrice(product: ProductInterface): boolean;
  getQtyConfig(product: ProductInterface): StockItem;
  getAttributeLabel(attributeName: string, optionId: string | number, attributes: Aggregation[]): string;
  getTypeOfProtection(product: Product): number | null;
  getCustomAttributesMap(customAttributes: Maybe<CustomAttributesInterface[]>): any;
  getCompositionOfFertilizer(product: Product, attributes: Aggregation[]): { label: string, link?: string }[];
  getIsOutOfStatus(product: Product): boolean;
  getInfoLabel(product: Product, attributes: Aggregation[]): string;
}

export const getCheapestVariant = (product: Product): Product | null => {
  if (!product || !product.variants) {
    return null;
  }
  const clonedVariants = JSON.parse(JSON.stringify(product.variants));
  clonedVariants.sort((a, b) => a.product.price_range.minimum_price.final_price.value - b.product.price_range.minimum_price.final_price.value);
  return clonedVariants[0].product;
};

export const getName = (product: ProductInterface): string => {
  if (!product) {
    return '';
  }

  return htmlDecode(product.name);
};

export const getSlug = (product: ProductInterface, category?: CategoryTree | CategoryInterface): string => {
  const rewrites = product?.url_rewrites;
  let url = product?.sku ? `/p/${product.sku}` : '';
  if (!rewrites || rewrites.length === 0) {
    return url;
  }

  url = `/${rewrites[0].url}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const rewrite of rewrites) {
    if (category && category.uid) {
      url = `/${rewrite.url}`;
      break;
    }
  }

  return url;
};

export const getPrice = (product: ProductInterface): Price => {
  let regular = 0;
  let special = null;
  let maximum = null;
  let final = null;
  if (product?.price_range) {
    regular = product.price_range.minimum_price.regular_price.value;
    final = product.price_range.minimum_price.final_price.value;
    maximum = product.price_range?.maximum_price?.final_price?.value;

    if (final < regular) {
      special = final;
    }
  }

  return {
    regular,
    special,
    maximum,
    final,
  };
};

export const isSpecialPrice = (specialPrice, omnibusPrice): boolean => {
  if (!(specialPrice && omnibusPrice)) return false;
  return (omnibusPrice - specialPrice) > 0;
};

export const getGallery = (product: Product, maxGallerySize = 4): MediaGalleryItem[] => {
  const images = [];

  if (
    !product?.media_gallery?.length
    && !product?.configurable_product_options_selection_all?.media_gallery?.length
    && !product?.gallery_selection?.media_gallery?.length
  ) {
    return images;
  }

  let selectedGallery = product.media_gallery;

  if (product?.gallery_selection?.media_gallery?.length) {
    selectedGallery = [...selectedGallery, ...product.gallery_selection.media_gallery];
  }

  if (product?.configurable_product_options_selection_all?.variant) {
    selectedGallery = product?.configurable_product_options_selection_all?.media_gallery || [];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const galleryItem of selectedGallery) {
    if (!galleryItem.disabled) {
      images.push({
        small: galleryItem.url,
        normal: galleryItem.url,
        big: galleryItem.url,
      });
    }
  }

  return images.slice(0, maxGallerySize);
};

export const getCoverImage = (product: Product): string => {
  if (!product || !product.image) {
    return null;
  }

  return product.image.url;
};

export const getProductThumbnailImage = (product: Product): string => {
  if (!product || !product.thumbnail) {
    return null;
  }

  return product.thumbnail.url;
};
// TODO: refactor with global store of all variants
export const getAttributeLabel = (
  attributeName: string,
  optionId: string | number,
  attributes: Aggregation[],
): string => {
  if (!attributes || attributes.length === 0) return '';

  if (!attributeName || !optionId) return '';
  const attribute = attributes.find((attr) => attr.attribute_code === attributeName);
  if (!attribute) return '';

  const option = attribute.options.find((opt) => opt.value.toString() === optionId.toString());
  if (!option) return '';

  return option.label;
};
export const getCompositionOfFertilizer = (product: Product, attributes: Aggregation[]): { label: string, link?: string }[] => {
  const result = attributes.reduce((acc, i) => {
    if (groupedRangeFilters.includes(i.attribute_code) && product[i.attribute_code] !== null) {
      acc.push({ label: `${i.label}: ${product[i.attribute_code]}%` });
    }
    return acc;
  }, []);
  return result.sort((a, b) => {
    const aVal = a.label.split(': ')[1].replace('%', '');
    const bVal = b.label.split(': ')[1].replace('%', '');
    return Number(bVal) - Number(aVal);
  });
};
export const getAdditionalFields = (product: Product, attributes: Aggregation[]): { label: string, value?: string }[] => {
  const result = attributes.reduce((acc, agg) => {
    if (productAdditionalFields.includes(agg.attribute_code) && product[agg.attribute_code]) {
      const value = agg?.options.find((opt) => opt.value.toString() === product[agg.attribute_code]?.toString())?.label;
      acc.push({ label: agg.label, value });
    }
    return acc;
  }, []);

  return result;
};
export const getCustomAttributesMap = (customAttributes: Maybe<CustomAttributesInterface[]>) => {
  if (!customAttributes) return null;
  const map: any = {};

  customAttributes.forEach((attribute) => {
    if (attribute?.entered_attribute_value?.value) {
      map[attribute.attribute_metadata.code] = attribute?.entered_attribute_value?.value || '';
    } else {
      map[attribute.attribute_metadata.code] = attribute?.selected_attribute_options?.attribute_option[0]?.label || '';
    }
  });

  return map;
};

export const getAttributes = (products: Product, _filterByAttributeName?: string[]): Record<string, ProductAttribute | string> => {
  if (!products || !products?.configurable_options) {
    return {};
  }

  const attributes = {};
  const configurableOptions = products.configurable_options;

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    attributes[option.attribute_code] = {
      code: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {};
        obj[value.value_index] = value.label;
        return obj;
      }),
    } as ProductAttribute;
  }
  return attributes;
};

export const getDescription = (product: Product): string => {
  if (!product || !product?.description) {
    return '';
  }

  return product.description.html;
};

export const getShortDescription = (product: ProductInterface): string => {
  if (!product || !product.short_description) {
    return '';
  }
  return product.short_description.html;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCategoryIds = (product: Product): string[] => {
  const categoryIds = [];

  if (!product?.categories) {
    return categoryIds;
  }

  return product.categories.map((category) => category.uid);
};

export const getCategory = (product: any, currentUrlPath: string): CategoryTree | null => {
  if (!product?.categories || product?.categories.length === 0) {
    return null;
  }

  const categories = currentUrlPath.split('/');
  categories.pop();

  if (categories.length === 0) {
    return null;
  }

  const categoryPath = categories.join('/');

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    if (`/${category.url_path}` === categoryPath) {
      return category;
    }
  }

  return null;
};

export const getId = (product: Product): string => product.uid;

export const getManufacturer = (product: Product): number => product.manufacturer;

export const getVariants = (product: Product): any[] => {
  const configurableOptions = product.configurable_options;

  if (!product || !configurableOptions || configurableOptions.length === 0) {
    return [];
  }

  return configurableOptions.map((variant) => ({
    name: variant.label,
    code: variant.attribute_code,
    options: variant.values.map((value) => ({
      label: value.label,
      uid: value.uid,
    })),
  }));
};

export const getConcatenatedVariantsLabels = (product: Product) => {
  const options = getVariants(product);
  if (options.length === 0) {
    return '';
  }
  // take the first option and concatenate its labels for future extensibility.
  if (options.length > 1 || options[0].options.length > 4) {
    return 'Produkt ma wiele opcji';
  }
  return options[0].options.map((opt) => opt.label).join(' | ');
};

export const isProductBestseller = (product: Product): boolean => product.bestseller === 1;
export const isProductRecommended = (product: Product): boolean => product.doradca === 1;
export const isProductVatOnly = (product: Product): boolean => product.invoice_only === 1;
export const isProductEko = (product: Product): boolean => product.certyfikat_eko === 1;
export const getInfoLabel = (product: Product, attributes: Aggregation[]): string => (product?.info_label ? getAttributeLabel('info_label', product.info_label, attributes) : '');

export const getDeliveryIn = (delivery_time: string | number | null, withLabel: boolean = true): string => {
  if (!delivery_time) return '';
  switch (Number(delivery_time)) {
    case 1:
      return `${withLabel ? 'wysyłka w ' : ''}24h`;
    case 2:
      return `${withLabel ? 'wysyłka w ' : ''}48h`;
    case 3:
      return `${withLabel ? 'wysyłka w ' : ''}72h`;
    case 4:
      return `${withLabel ? 'wysyłka w ' : ''}1-3 dni`;
    default:
      return `${withLabel ? 'wysyłka w ' : ''}${delivery_time} dni`;
  }
};

export const getProductAttachments = (product: Product) => product?.productAttachments || [];
export const getProductSku = (product: Product): string => product.sku;
export const getTypeOfProtection = (product: Product): number | null => product?.rodzaj_srodka_ochrony || null;
// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const getTypeId = (product: Product): string => product.__typename;

export const getProductEan = (product: Product) => product?.ean;
export const isProductConfigurable = (product: Product): boolean => getTypeId(product) === 'ConfigurableProduct';

export const getIsOutOfStatus = (product: Product): boolean => {
  if (isProductConfigurable(product)) {
    return !!product.variants && !!product.variants?.every((variant) => variant.product.stock_status === 'OUT_OF_STOCK');
  }
  return product.stock_status === 'OUT_OF_STOCK';
};

export const getDeliveryDate = (product: Product) => {
  const month = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ];

  if (product.estimated_avalibility !== null && product.estimated_avalibility !== undefined) {
    const date = new Date(product.estimated_avalibility);
    const monthName = month[date.getMonth()];
    const yearName = date.getFullYear();
    return `dostawa ok. ${monthName} ${yearName}`;
  }

  if (isProductConfigurable(product)) {
    const dates = product?.variants
      ?.filter((variant) => variant.product.estimated_avalibility !== null && variant.product.estimated_avalibility !== undefined)
      ?.map((variant) => new Date(variant.product.estimated_avalibility));

    if (dates && dates.length > 0) {
      let earliestDate = dates[0];

      for (let i = 1; i < dates.length; i++) {
        if (dates[i] < earliestDate) {
          earliestDate = dates[i];
        }
      }

      const monthName = month[earliestDate.getMonth()];
      const yearName = earliestDate.getFullYear();
      return `dostawa ok. ${monthName} ${yearName}`;
    }
  }

  return '';
};

export const getAvailableType = (product: Product): ProductAvailable => {
  if (isProductProductWithdrawn(product)) {
    return availableList('WITHDRAWN');
  }
  if (isProductInStock(product)) {
    if (isProductOrderNumber(product)) {
      return availableList('PHONE_ORDER');
    }
    return availableList('IN_STOCK');
  }
  // TODO if not is stock and salable
  // if (isProductOnlyXLeftInStock(product)) {
  //   return availableList('OUT_OF_STOCK_TEMPORARY');
  // }
  return availableList('OUT_OF_STOCK');
};
const getCategoryBreadcrumbs = (category: CategoryInterface): Breadcrumb[] => {
  const breadcrumbs = [];

  if (!category) {
    return [];
  }

  if (Array.isArray(category?.breadcrumbs)) {
    const blockedCategoryIds = new Set([977, 950, 154, 85, 152, 179]);

    const checkIsCategoryBlocked = (cat) => blockedCategoryIds.has(cat.category_id as number);

    const currentCategory: any = {
      category_id: category.id,
      category_name: category.name,
      category_url_path: category.url_path,
      url_suffix: category.url_suffix,
    };

    const categoriesCombined = [...category.breadcrumbs, currentCategory];
    const indexesToSkip = new Set<number>();

    categoriesCombined.forEach((cat, index) => {
      if (indexesToSkip.has(index)) return;

      if (checkIsCategoryBlocked(cat)) {
        // Skip the next category (child category) and the current one
        indexesToSkip.add(index + 1);
        return;
      }

      breadcrumbs.push({
        text: cat.category_name,
        link: `/${cat.category_url_path}${cat.url_suffix || ''}`,
      } as Breadcrumb);
    });
  } else {
    breadcrumbs.push({
      text: category.name,
      link: `/${category.url_path}${category.url_suffix || ''}`,
    } as Breadcrumb);
  }

  return breadcrumbs;
};

export const getBreadcrumbs = (product: ProductInterface, category?: CategoryInterface): Breadcrumb[] => {
  let breadcrumbs = [];

  if (!product) {
    return breadcrumbs;
  }

  if (category) {
    breadcrumbs = getCategoryBreadcrumbs(category) as Breadcrumb[];
  }

  breadcrumbs.push({
    text: getName(product),
    link: `/${product?.url_rewrites?.[0]?.url ?? product.url_key}`,
  });

  return breadcrumbs;
};

export const getProductRelatedProduct = (product: any): Product[] => product?.related_products || [];

export const getProductUpsellProduct = (product: any): Product[] => product?.upsell_products || [];

export const getSwatchData = (swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined => swatchData?.value;

const sortProduct = (a, b) => a.position - b.position;

export const getGroupedProducts = (
  product: GroupedProduct & {
    __typename: string;
  },
  // eslint-disable-next-line no-underscore-dangle
): GroupedProduct['items'] | undefined => product.__typename === 'GroupedProduct' && product?.items?.sort(sortProduct);

// eslint-disable-next-line no-underscore-dangle
export const getBundleProducts = (
  product: BundleProduct & {
    __typename: string;
  },
  // eslint-disable-next-line no-underscore-dangle
): BundleProduct['items'] | undefined => product.__typename === 'BundleProduct' && product?.items?.sort(sortProduct);

export const getIsNewProduct = (product: Product): boolean => {
  const currentDate = new Date();
  const newFromDate = product.new_from_date && new Date(product.new_from_date);
  const newToDate = product.new_to_date && new Date(product.new_to_date);

  if (newFromDate && newToDate) {
    return newFromDate <= currentDate && newToDate >= currentDate;
  }

  if (newFromDate) {
    return newFromDate <= currentDate;
  }

  if (newToDate) {
    return newToDate >= currentDate;
  }

  return false;
};

export const getAgrofag = (product: Product): string[] => {
  if (!product?.agrofag) {
    return [];
  }
  return product?.agrofag.split(',');
};
export const getGrupaUpraw = (product: Product): string[] => {
  if (!product?.grupa_upraw) {
    return [];
  }
  return product?.grupa_upraw.split(',');
};
export const getActiveSubstance = (product: Product): string[] => {
  if (!product?.active_substance) {
    return [];
  }
  return product?.active_substance.split(',');
};
export const getPurpose = (product: Product): string[] => {
  if (!product?.purpose) {
    return [];
  }
  return product?.purpose.split(',');
};
export const getDosage = (product: Product) => {
  if (!product || !product?.dosage) {
    return '';
  }
  return product.dosage;
};
export const getUpsellProducts = (product: Product): string[] => {
  if (!product?.upsell_products) {
    return [];
  }
  return product.upsell_products.map((prod) => prod.sku);
};
export const getRelatedProducts = (product: Product): string[] => {
  if (!product?.related_products) {
    return [];
  }
  return product.related_products.map((prod) => prod.sku);
};
export const getCrossSellProducts = (product: Product): string[] => {
  if (!product?.crosssell_products) {
    return [];
  }
  return product.crosssell_products.map((prod) => prod.sku);
};
export const getSubstituteProducts = (product: Product): string[] => {
  if (!product?.substitute_products) {
    return [];
  }
  return product.substitute_products.map((prod) => prod.sku);
};

export const getQtyConfig = (product: ProductInterface): StockItem => {
  if (!product || !product.stock_item) {
    return {
      max_sale_qty: 9999,
      min_sale_qty: 1,
      qty_increments: 1,
    };
  }
  return {
    ...product.stock_item,
    qty_increments: product.stock_item.qty_increments || 1,
  };
};

const productGetters: ProductGetters = {
  getCheapestVariant,
  getAttributeLabel,
  isSpecialPrice,
  getAttributes,
  getBreadcrumbs,
  getCategory,
  getCategoryIds,
  getCoverImage,
  getDescription,
  getGallery,
  getId,
  getName,
  getPrice,
  getProductRelatedProduct,
  getProductSku,
  getProductThumbnailImage,
  getProductUpsellProduct,
  getShortDescription,
  getSlug,
  getTypeId,
  getSwatchData,
  getGroupedProducts,
  getBundleProducts,
  getManufacturer,
  getVariants,
  getConcatenatedVariantsLabels,
  isProductBestseller,
  isProductEko,
  isProductRecommended,
  isProductVatOnly,
  getDeliveryIn,
  isProductConfigurable,
  getAvailableType,
  getDeliveryDate,
  getIsNewProduct,
  getProductAttachments,
  getProductEan,
  getAgrofag,
  getGrupaUpraw,
  getActiveSubstance,
  getPurpose,
  getDosage,
  getUpsellProducts,
  getRelatedProducts,
  getCrossSellProducts,
  getSubstituteProducts,
  getPriceTiersWithCustomerGroup,
  getPriceForLoggedCustomer,
  getPriceThreshold,
  isTableForCustomerGroupPrice,
  isLoggedCustomerGroupPrice,
  getQtyConfig,
  getTypeOfProtection,
  getCustomAttributesMap,
  getCompositionOfFertilizer,
  getIsOutOfStatus,
  getInfoLabel,
};

export default productGetters;
