import fragmentPriceRangeFields from '~/modules/GraphQL/customQueries/fragments/fragmentPriceRangeFields';
import fragmentOptimizedVariantFields from '~/modules/GraphQL/customQueries/fragments/fragmentOptimizedVariantFields';
import fragmentOptimizedProductFieldsWithoutCategories from '~/modules/GraphQL/customQueries/fragments/fragmentOptimizedProductFieldsWithoutCategories';
import fragmentProductCompositionFields from '~/modules/GraphQL/customQueries/fragments/fragmentProductCompositionFields';
import fragmentProductAdditionalFields from '~/modules/GraphQL/customQueries/fragments/fragmentProductAdditionalFields';
/**
 * GraphQL Query that fetches the list of products with details using sort,
 * filters and pagination.
 */
export default `
  #graphql
  query productDetails(
    $search: String = "",
    $filter: ProductAttributeFilterInput,
    $pageSize: Int = 10,
    $currentPage: Int = 1,
    $sort: ProductAttributeSortInput
  ) {
    products(search: $search, filter: $filter, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
      items {
        ...OptimizedProductFieldsWithoutCategories
        ...ProductCompositionFields
        ... on SimpleProduct {
          ...ProductAdditionalFields
        }
        agrofag
        grupa_upraw
        active_substance
        purpose
        rodzaj_srodka_ochrony
        info_label
        certyfikat_eko
        crosssell_products {
          sku
        }
        related_products{
          sku
        }
        upsell_products {
          sku
        }
        substitute_products{
          sku
        }
        price_tiers_with_customer_group {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
          customer_group_id
        }
        ean
        opisowe_szczegoly_produktu_dropdown
        dosage
        productAttachments {
          file
          title
          type
          url
        }
        #use in product getters
        url_key
        url_rewrites {
          url
        }
        #For breadcrumbs
        categories {
          id
          name
          level
          path
          uid
          url_suffix
          url_path
          breadcrumbs {
            category_name
            category_url_path
            category_id
          }
        }
        #image gallery
        image {
          url
          position
          disabled
          label
        }
        media_gallery {
          url
          position
          disabled
          label
        }
        #meta tags
        meta_description
        meta_keyword
        meta_title
        description {
          html
        }
        short_description {
          html
        }
        price_range {
          ...PriceRangeFields
        }

        ... on ConfigurableProduct {
          variants {
            attributes {
              code
              value_index
            }

            product {
              ...OptimizedVariantFields
              stock_status
              price_range {
                ...PriceRangeFields
              }
            }
          }

          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              swatch_data {
                value
              }
              uid
              value_index
            }
          }
          gallery_selection: configurable_product_options_selection_all(configurableOptionValueUids: []) {
            media_gallery {
              disabled
              label
              position
              url
            }
          }
        }

        ... on VirtualProduct {
          product_links {
            link_type
            linked_product_sku
            linked_product_type
            position
            sku
          }
        }

        ... on BundleProduct {
          dynamic_sku
          items {
            position
            __typename
            required
            sku
            title
            type
            uid
            options {
                uid
                quantity
                position
                is_default
                price
                price_type
                can_change_quantity
                label
                product {
                  active_substance
                  agrofag
                  bestseller
                  categories {
                    id
                    name
                    level
                  }
                  delivery_time
                  doradca
                  dosage
                  ean
                  estimated_avalibility
                  grupa_upraw
                  id
                  invoice_only
                  manufacturer
                  name
                  new_from_date
                  new_to_date
                  rodzaj_srodka_ochrony
                  info_label
                  certyfikat_eko
                  order_number
                  price_range {
                    maximum_price {
                      final_price {
                        currency
                        value
                      }
                      regular_price {
                        currency
                        value
                      }
                    }
                    minimum_price {
                      final_price {
                        currency
                        value
                      }
                      regular_price {
                        currency
                        value
                      }
                    }
                  }
                  price_tiers_with_customer_group {
                    discount {
                      amount_off
                      percent_off
                    }
                    final_price {
                      currency
                      value
                    }
                    quantity
                    customer_group_id
                  }
                  productAttachments {
                    file
                    title
                    type
                    url
                  }
                  product_withdrawn
                  purpose
                  short_description {
                    html
                    __typename
                  }
                  sku
                  special_from_date
                  special_price
                  special_to_date
                  stock_item {
                    max_sale_qty
                    min_sale_qty
                    qty_increments
                  }
                  stock_status
                  thumbnail {
                    disabled
                    label
                    position
                    url
                  }
                  uid
                }
              }
          }
        }

        product_links {
          link_type
          linked_product_sku
          linked_product_type
          position
          sku
        }
      }
    }
  }
  ${fragmentPriceRangeFields}
  ${fragmentOptimizedProductFieldsWithoutCategories}
  ${fragmentOptimizedVariantFields}
  ${fragmentProductCompositionFields}
  ${fragmentProductAdditionalFields}
`;
